.presentation-container {
    padding-bottom: 40px;
}

.presentation-content {
    border-top: 1px solid var(--gold);
    border-bottom: 1px solid var(--gold);
    display: flex;
    min-height: 281px;
}

.presentation-content img {
    padding-left: 135px;
    padding-right: 30px;
    object-fit: cover;
    max-width: 270px;
}

.presentation-text-container .line-container {
    padding-top: 8px;
    margin-bottom: 0;
}

.presentation-text-container {
    padding: 40px 135px 0 30px;
}

.presentation-content-text {
    padding: 24px 24px 0 100px;
}

.presentation-content-text p.regular {
    margin-bottom: 33px;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .presentation-content img {
        padding-left: 16px;
        padding-right: 0;
        height: 350px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .presentation-container {
        padding-bottom: 60px;
    }

    .presentation-content {
        padding: 0 16px;
    }

    .presentation-content > div {
        margin-top: 16px;
    }

    .presentation-content img {
        padding-left: 0;
        padding-right: 15px;
        width: 170px;
        object-position: 60%;
    }

    .presentation-content-text {
        padding-bottom: 13px;
    }

    .presentation-content .line {
        display: none;
    }

    .presentation-text-container {
        padding: 0;
    }

    .presentation-content-text {
        padding: 19px 0 0 0;
    }

    .presentation-content-text p.regular {
        margin-bottom: 0;
    }
}
