.article-center-container:hover .article-center-image-container img {
    top: 20px;
    left: 20px;
    outline: 1px solid var(--white);
}

.article-center-container:hover button.secondary {
    color: var(--black);
}

.article-center-container:hover .arrow-right {
    padding-left: 8px;
}

.article-center-container:hover .arrow-right path {
    stroke: var(--black);
}

.article-center-image-container {
    position: relative;
    height: 350px;
    padding-top: 20px;
    padding-left: 20px;
}

.article-center-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
    outline: 0 var(--white);
}

.article-center-frame {
    outline: 1px solid var(--black);
    height: 99%;
    width: 550px;
}

.article-center-text-button-container {
    padding: 24px 24px 0 100px;
}

.article-center-container button {
    padding: 0 24px 0 100px;
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .article-center-image-container img {
        width: calc(59vw - 10px);
        object-fit: cover;
    }

    .article-center-frame {
        width: calc(59vw - 10px);
    }
}

@media screen and (max-width: 768px) {
    .article-center-container > div {
        margin-left: 0;
    }

    .article-center-content-container {
        outline: none;
        height: 150px;
    }

    .article-center-content {
        position: static;
    }

    .article-center-content img {
        width: 382px;
        height: 150px;
    }

    .line-text-container > p.regular {
        padding: 0;
    }

    .read-button {
        padding-left: 0;
    }

    .article-center-container button.secondary {
        margin: auto;
        width: 100%;
        max-width: calc(50% - 7px);
        justify-content: center;
    }

    .article-center-container button svg {
        display: none;
    }

    .article-center-container:hover button.secondary {
        color: var(--white);
    }

    .container button.secondary {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 14px 24px;
        color: var(--white);
        background: var(--black);
        border: none;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
    }

    .container button.secondary:hover {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--black);
        background: var(--white);
        outline: 1px solid var(--black);
    }

    .article-center-content {
        position: static;
    }

    .article-center-image-container {
        padding: 0;
        height: auto;
    }

    .article-center-image-container img {
        width: 100%;
        height: 150px;
        position: unset;
        object-fit: cover;
    }

    .article-center-frame {
        outline: none;
        display: none;
    }

    .article-center-text-button-container {
        padding: 16px 0;
    }
}
