.article-left-container:hover .article-left-image-container img {
    top: 20px;
    left: 20px;
    outline: 1px solid var(--white);
}

.article-left-container:hover button.secondary {
    color: var(--black);
}

.article-left-container:hover .arrow-right {
    padding-left: 8px;
}

.article-left-container:hover .arrow-right path {
    stroke: var(--black);
}

.article-left-image-container {
    position: relative;
    height: 203px;
    padding-top: 20px;
    padding-left: 20px;
}

.article-left-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s linear;
    outline: 0 var(--white);
}

.article-left-frame {
    outline: 1px solid var(--black);
    height: 100%;
    width: 250px;
}

.article-left-container .h2-container {
    padding-bottom: 24px;
}

@media screen and (max-width: 768px) {
    .article-left-container {
        display: flex;
        flex-direction: column;
    }

    .article-left-container > button:last-of-type {
        margin-top: auto;
    }

    .article-left-container > div {
        margin-left: 0;
    }

    .article-left-content-container {
        outline: none;
        width: 184px;
        height: 150px;
    }

    .article-left-container:hover button.secondary {
        color: var(--white);
    }

    .article-left-container .h2-container h2 {
        font-family: 'Roboto';
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1px;
    }

    .article-left-container p.regular {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .article-left-container button {
        /*margin: auto;*/
    }

    .article-left-container button svg {
        display: none;
    }

    .container button.secondary {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 14px 24px;
        color: var(--white);
        background: var(--black);
        border: none;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
    }

    .container button.secondary:hover {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--black);
        background: var(--white);
        outline: 1px solid var(--black);
    }

    .article-left-content {
        position: static;
    }

    .article-left-container button.secondary {
        width: 100%;
        justify-content: center;
    }

    .article-left-image-container {
        padding: 0;
        height: 150px;
    }

    .article-left-image-container img {
        max-width: 100%;
        width: 100%;
        max-height: 150px;
        height: auto;
        position: unset;
        object-fit: cover;
    }

    .article-left-frame {
        outline: none;
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .article-left-image-container img {
        max-width: 448px;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
}