@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");


@font-face {
    font-family: "Roboto";
    src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "RobotoBold";
    src: url("assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

/* DESKTOP */

:root {
    --red: #E74C3C;
    --gold: #B09A48;
    --black: #222222;
    --dark-grey: #8E8E8E;
    --light-grey: #EEEEEE;
    --light-grey-footer: #F2F2F2;
    --white: #FFFFFF;
    --brown: #504136;
    --font-family: 'Roboto', sans-serif;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 48px;
    margin: auto auto 80px;
    max-width: 1170px;
}

.hero {
    padding: 0;
    display: flex;
    height: 630px;
    flex-direction: row;
    position: relative;
    align-items: center;
}

.hero-content-container {
    position: relative;
    margin-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    border: 1px solid var(--black);
    z-index: 1;
    height: fit-content;
}

.hero-content {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    float: left;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    background: var(--white);
    width: 620px;
    min-height: 241px;
    gap: 40px;
    margin-top: -20px;
    margin-left: -20px;
}

.hero-image {
    position: absolute;
    right: calc(50% - 50vw);
}

.hero-image img {
    object-fit: cover;
    width: 100%;
}

.d-flex {
    display: flex;
}

.gap-30 {
    gap: 30px;
}

.w-100 {
    width: 100%;
}

.w-30 {
    width: 30%;
}

.w-70 {
    width: 70%;
}

.justify-content-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.position-relative {
    position: relative;
}

.line-container {
    display: flex;
    margin-bottom: 24px;
}

.line {
    width: 32px;
    margin-right: 8px;
    border-bottom: 1px solid var(--dark-grey);
}

h1 {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 46px;
    color: var(--black);
    margin: 0;
}

h2 {
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    font-size: 31px;
    line-height: 35px;
    color: var(--black);
    margin: 0;
}

h3 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 1px;
    color: var(--black);
    margin: 0;
}

h4 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
    color: var(--black);
    margin: 0;
}

h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    color: var(--black);
    margin: 0;
}

p.regular {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: var(--black);
    margin: 0;
    white-space: pre-wrap;
}

p.bold, b, strong {
    font-family: 'RobotoBold';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: var(--black);
    margin: 0;
}

.caption-regular {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    color: var(--black);
    margin: 0;
}

.caption-bold {
    font-family: 'RobotoBold';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    color: var(--black);
    margin: 0;
}

button.primary {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--gold);
    background: unset;
    border: none;
    transition: all 0.4s ease-in-out;
    padding: 12px 12px;
    text-align: center;
    background: linear-gradient(var(--gold) 0%, var(--gold) 100%)
    left /100% var(--d, 3%) no-repeat;
    background-position: left 100% bottom 0;
    border-width: 0;
    cursor: pointer;
    text-decoration: none;
}

button.primary:hover {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--white);

    --d: 100%;
}

button.primary:hover .arrow-right path {
    stroke: var(--white);
}

.arrow-right {
    height: 16px;
    transition: all 0.4s ease-in-out;
}

.arrow-right path {
    stroke: var(--gold);
    transition: stroke 0.4s ease-in-out;
}

.arrow-down {
    height: 16px;
    transition: all 0.4s ease-in-out;
}

.arrow-down path {
    stroke: var(--gold);
    transition: stroke 0.4s ease-in-out;
}

.external-link {
    height: 21px;
    width: 21px;
    transition: all 0.4s ease-in-out;
    padding-right: 8px;
}

.external-link path {
    stroke: var(--gold);
    transition: stroke 0.4s ease-in-out;
}

button.secondary {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--gold);
    background: unset;
    border: none;
    transition: all 0.4s ease-in-out;
    padding-left: 0;
    cursor: pointer;
    height: 48px;
}

button.secondary:hover {
    color: var(--black);
}

button.secondary:hover .arrow-right {
    padding-left: 8px;
}

button.secondary:hover .external-link path {
    stroke: var(--black);
}

button.secondary:hover .arrow-right path {
    stroke: var(--black);
}

button.tertiary {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: var(--gold);
    background: unset;
    border: none;
    transition: all 0.4s ease-in-out;
    padding: 12px 12px;
    text-align: center;
    background: linear-gradient(var(--gold) 0%, var(--gold) 100%)
    left /100% var(--d, 3%) no-repeat;
    background-position: left 100% bottom 0;
    border-width: 0;
    cursor: pointer;
}

button.tertiary svg {
    transition: all 0.3s ease-in-out;
}

button.tertiary svg.opened {
    transform: rotateZ(180deg);
}

button.primary-small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 14px 24px;
    color: var(--white);
    background: var(--black);
    border: none;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

button.primary-small:hover {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--black);
    background: var(--white);
    outline: 1px solid var(--black);
}

button.secondary-small {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--black);
    background: var(--white);
    border: none;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

button.secondary-small:hover {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--white);
    background: var(--black);
}

.card {
    border: 1px solid var(--black);
}

.card-picture {
    width: 370px;
    height: 502px;
}

.card-picture-header {
    background: var(--black);
}

.card-picture-content {
    width: 370px;
    height: 132px;
}

.card-picture-background {
    width: 270px;
    height: 436px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.card-picture-background-header {
    width: 270px;
    height: 270px;
    background: var(--black);
}

.card-picture-background-content {
    width: 270px;
    height: 166px;
    background: var(--white);
}

p > a {
    color: var(--black);
    text-decoration: underline;
}

a {
    text-decoration: none;
}

.footer-list {
    background: var(--light-grey-footer);
    padding: 120px 135px;
}

.footer-list-block {
    display: flex;
    flex-direction: column;
    gap: 17px;
}

.footer-center {
    background: var(--light-grey-footer);
    padding: 220px;
}

.footer-center-header {

}

.footer-center-hr {
    border: 1px solid var(--black);
    width: 1000px;
}

.footer-center-socials {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
}

.footer-center-captions {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.footer-split {
    background: var(--light-grey-footer);
    padding: 30px 135px;
}

.footer-split-logo {

}

.footer-split-blog-post {
    display: flex;
    flex-direction: column;
}

.footer-split-hr {
    border: 1px solid var(--black);
    height: 320px;
}

.footer-split-links-right {
    display: flex;
    flex-direction: row;
}

.footer-split-captions {
    display: flex;
    flex-direction: row;
}

.text-decoration-none {
    text-decoration: none;
}

.pointer-none {
    cursor: default;
}

@media screen and (max-width: 991px) {
    .container {
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .container {
        gap: 40px;
        margin-bottom: 80px;
    }

    .hero {
        /*height: 500px;*/
        height: auto;
        padding-bottom: 0;
        align-items: flex-start;
    }

    .hero-content-container {
        margin-top: 40px;
        margin-right: 16px;
        width: 100%;
        padding-right: 60px;
    }

    .hero-content {
        width: 100%;
        padding: 24px 32px;
    }


    h1 {
        font-size: 36px;
        line-height: 40px;
    }

    h5 {
        font-size: 10px;
        line-height: 12px;
    }

    .line {
        width: 16px;
    }
}