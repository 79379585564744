.form-container {
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 20px;
}

form input {
    height: 21px;
    cursor: pointer;
    padding: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--dark-grey);
    transition: all 0.3s ease-in-out;
    border: none;
    outline: 1px solid var(--black);
}

form input::placeholder,
form textarea::placeholder {
    color: var(--dark-grey);
    transition: all 0.3s ease-in-out;
}

form input:focus::placeholder,
form textarea:focus::placeholder {
    color: var(--gold);
    transition: all 0.3s ease-in-out;
}

form input:focus:hover::placeholder,
form textarea:focus:hover::placeholder {
    color: var(--gold);
    transition: all 0.3s ease-in-out;
}

form input:hover,
form textarea:hover {
    outline-color: var(--gold);
}

form input:hover::placeholder,
form textarea:hover::placeholder {
    color: var(--black);
}

form textarea {
    height: 188px;
    cursor: pointer;
    padding: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    resize: none;
    transition: all 0.3s ease-in-out;
    border: none;
    outline: 1px solid var(--black);
}

.input-filled {
    color: var(--gold);
    outline-color: var(--gold);
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.button-container button {
    flex: 0.5;
    justify-content: center;
}

.error-text {
    color: var(--red);
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    margin: 0;
}

.button-disabled {
    opacity: 0.5;
}

.success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 80px;
    margin-bottom: 371px;
}

.success-message > div > p {
    text-align: center;
}

@media screen and (max-width: 768px) {
    form {
        max-width: unset;
        padding: 16px;
        margin: unset;
    }
}