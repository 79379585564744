.hero-portfolio-content-container {
    position: relative;
    margin-top: 168px;
    border: 1px solid var(--black);
    height: 235px;
    width: 700px;
    z-index: 1;
    margin-left: 20px;
}

.hero-portfolio-content {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    float: left;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    background: var(--white);
    width: 620px;

    position: absolute;
    top: -20px;
    left: -20px;
}

.hero-portfolio-image {
    position: absolute;
    right: 0;
}

.hero-portfolio-image img {
    width: 805px;
    object-fit: cover;
}

.portfolio-books-container {
    display: flex;
    max-width: 1170px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 180px;
    row-gap: 160px;
    margin: auto;
    padding: 0 0 80px;
}

.portfolio-book-wrapper {
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
}

.portfolio-book-wrapper button.tertiary {
    display: none;
}

.portfolio-book-container {
    position: relative;
    outline: 1px solid var(--black);
    height: 370px;
    width: 250px;
    z-index: 1;
    cursor: pointer;
}

.portfolio-book {
    display: flex;
    flex-direction: column;
    float: left;
    width: 250px;
    position: absolute;
    top: -20px;
    left: -20px;
    transition: all 0.4s ease-in-out;
}

.portfolio-book img:nth-child(1) {
    object-fit: cover;
    opacity: 1;
    transition: all 0.4s ease-in-out;
}

.portfolio-book img:nth-child(2) {
    position: absolute;
    object-fit: cover;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.portfolio-book-container:hover .portfolio-book {
    top: 0;
    left: 0;
}

.portfolio-book-container:hover .portfolio-book img:nth-child(1) + .portfolio-book img:nth-child(2) {
    opacity: 0;
}

.portfolio-book-container:hover .portfolio-book img:nth-child(2) {
    opacity: 1;
}

.portfolio-book-content {
    height: 350px;
    width: 218px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    top: 0;
    padding: 16px;
    transition: all 0.4s ease-in-out;
    opacity: 0;
    background: var(--white);
    font-size: 14px;
    z-index: 0;
    margin-top: 20px;
    line-height: 19px;
    font-family: var(--font-family);
}

.portfolio-book-content.clicked {
    z-index: 1;
    opacity: 1;
    cursor: pointer;
    outline: 1px solid var(--black);
}

.portfolio-book-container.disabled-effects {
    top: 0;
    pointer-events: none;
}

.portfolio-book-content-title .bolder {
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.portfolio-book-content-subtitle {
    display: inline-block;
}

.portfolio-book-content ul {
    padding-inline-start: 24px;
    margin: 0;
}

.portfolio-book-content li::marker {
    font-size: 12px;
}

.portfolio-book-content-text p{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}
.tooltip li{
    display: none;
}

.tooltip p{
    font-size: 14px;}

@media screen and (max-width: 768px) {
    .portfolio-books-container {
        margin: 0 15px;
        justify-content: center;
        align-items: flex-start;
        gap: 15px;
        max-width: unset;
        padding: 0 0 80px;
    }

    .portfolio-books-container > div {
        max-width: 184px;
    }

    .portfolio-book-wrapper {
        padding-left: 0;
        padding-top: 0;
    }

    .portfolio-book-wrapper button.tertiary {
        display: block;
    }

    .portfolio-book-container {
        height: 300px;
        width: 184px;
        cursor: default;
        pointer-events: none;
    }

    .portfolio-book {
        width: 184px;
        top: 0;
        left: 0;
    }

    .portfolio-book img {
        width: 184px;
        height: 300px;
    }

    .portfolio-book-content {
        pointer-events: none;
        max-height: 0;
        width: 152px;
        margin-top: 0;
        position: unset;
        opacity: 0;
        transition: all 0.4s ease-in-out;
    }

    .portfolio-book-content.clicked {
        opacity: 1;
        max-height: 400px;
        margin-top: 20px;
    }

    .portfolio-book-content-text {
        -webkit-line-clamp: 6;
    }
}


