.h4-container {
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    gap: 32px;
}

.h4-container a {
    width: fit-content;
}

.articles-container {
    display: flex;
    gap: 30px;
}

.articles-container-mobile {
    display: none;
    gap: 30px;
}

.articles-left-container {
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    gap: 40px;
}

.articles-left-container a {
    max-width: 270px;
}

.articles-center-container {
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    gap: 40px;
}

.articles-center-container a {
    max-width: 570px;
}

.articles-right-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
}

.articles-right-container a {
    max-width: 270px;
}

.h5-container {
    display: flex;
}

.h2-container {
    display: flex;
}

.more-articles-button-container {
    display: flex;
    justify-content: center;
    padding: 80px 0;
}

.line-text-container {
    padding: 24px 0 8px;
}

.line-text-container .line-container {
    margin-bottom: 16px;
}


@media screen and (max-width: 1024px) {
    .hero {
        padding-left: 0;
    }

    .hero-image {
        left: 0;
    }

    .hero-image img {
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .articles-container-mobile {
        display: flex;

        padding: 0 30px;
    }

    .articles-container {
        display: none;

        flex-wrap: wrap;
        padding: 0 30px;
    }

    .articles-left-container a {
        max-width: 100%;
    }

    .articles-center-container a {
        max-width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .hero {
        padding-top: 0;
    }

    .articles-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .articles-left-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .articles-right-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .articles-center-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .hero-image img {
        height: 250px;
    }

    .articles-left-container,
    .articles-right-container{
        gap: 14px;
        width: 100%
    }

    .articles-left-container a {
        flex: 1;
    }

    .articles-right-container a {
        flex: 1;
    }

    .h4-container {
        padding: 0;
    }

    .articles-container {
        margin: 16px;
        gap: 40px;
    }

    .article-left-container button.secondary {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .articles-left-container a {
        max-width: 100%;
    }

    .articles-right-container a {
        max-width: 100%;
    }
}