.navbar-container {
    position: fixed;
    z-index: 20;
    width: 100%;
    margin: auto auto;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    background: var(--white);
}

.navbar-center {
    display: flex;
    flex-direction: row;
    width: 1170px;
    padding: 9px;
    z-index: 11;
    height: 30px;
    gap: calc(45% - 140px);
    margin: auto;
}

.navbar-center-logo {
    width: 150px;
    height: 30px;
}

.navbar-center-logo > div {
    background-image: url("../../assets/img/logo/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 30px;
}

.navbar-center-links {
    display: flex;
    gap: 42px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.navbar-center-links a {
    text-decoration: none;
}

.navbar-center-link {
    transition: border-bottom-width 1s ease-in-out;
    cursor: pointer;
}

.navbar-center-link:not(.selected):hover > div + .border-bottom {
    width: 100%;
}

.navbar-center-link.selected > .caption-regular {
    color: var(--gold);
}

.navbar-center-link.selected > .border-bottom {
    width: 50%;
}

.border-bottom {
    margin-top: 4px;
    width: 0;
    height: 1px;
    background: var(--gold);
    transition: width 0.4s ease-in-out;
}

.navbar-center-hamburger-container {
    display: none;
    position: relative;
    padding: 16px;
}

.navbar-center-hamburger {
    cursor: pointer;
}

.bar1, .bar2, .bar3 {
    width: 28px;
    height: 3px;
    background-color: var(--white);
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    transform: translate(0, -7px) rotate(45deg);
}

.mobile-navlinks {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -310px;
    left: -90px;
    background-color: var(--gold);
    padding: 34px;
    border-radius: 10px;
    gap: 36px;
    transition: all 0.4s ease-in-out;
    visibility: visible;
    opacity: 1;
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

@media screen and (max-width: 991px) {
    .navbar-container {
        bottom: 0;
        width: 100%;
    }

    .navbar-center {
        bottom: 0;
        background-color: var(--gold);
        padding: 0;
        /*height: unset;*/
        height: 76px;
        justify-content: space-between;
        gap: 0;
        width: 100%;
    }

    .navbar-center-logo {
        width: auto;
        margin: 16px;
    }

    .navbar-center-logo > div {
        background-image: url("../../assets/img/logo/logo-responsive.png");
        background-size: cover;
        height: 42px;
        width: 213px;
    }

    .navbar-center-links {
        display: none;
    }

    .mobile-navlinks h4 {
        margin-top: 0;
        color: var(--white);
    }

    .navbar-center-link {
        width: fit-content;
    }

    .navbar-center-link.selected h4 {
        color: var(--black);
    }

    .navbar-center-link.selected .border-bottom {
        background: var(--black);
        width: 50%;
    }

    .navbar-center-hamburger-container {
        display: flex;
        align-items: center;
    }
}