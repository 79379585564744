.article-hero {
    display: flex;
    gap: 30px;
    max-width: 1170px;
    margin: auto;
    padding-top: 48px;
    padding-left: 16px;
    align-items: stretch;
    height: auto;
}

.article-hero > div.d-flex {
    max-width: 470px;
}

.article-hero > div > div {
    display: flex;
    padding-top: 32px;
}

.article-hero h2 {
    margin-top: 0;
}

.article-hero-text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 0.5;
}

.article-hero-text-container > .line-container {
    margin-bottom: 0;
}

.article-image {
    display: flex;
    flex: 0.3;
    height: 100%;
}

.article-image img {
    width: calc(100% + 22vw);
    object-fit: cover;
}

.article-content-container {
    display: flex;
    flex-direction: column;
    max-width: 1070px;
    gap: 24px;
    margin: auto;
    padding: 0 16px;
}


.article-content-container h3,
.article-content-container p,
.article-content-container .caption-regular {
    color: var(--brown);
}


.article-content-offset {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-left: 100px;
}

.article-content-offset p {
    max-width: 770px;
}

.article-content-media-container {
    display: flex;
    gap: 30px;
    margin-top: 51px;
}

.article-content-media-container video {
    height: 430px;
    width: 770px;
}

.video-container {
    background: var(--light-grey);
    position: relative;
    width: 770px;
}

.video-container svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 19px 19px 19px 25px;
    margin: auto;
    background: var(--white);
}

.links-container {
    display: flex;
    gap: 50px;
    margin-top: 6px;
}

.article-content-offset .links-container > button {
    width: 310px;
}

.right-content {
    width: 40%;
}

.border-top {
    width: 100%;
    border-top: 1px solid var(--gold);
    margin-bottom: 16px;
}

.carousel-container {
    position: relative;
    width:60%;
    height: 430px;
    background: var(--light-grey);
}

.carousel img {
    width: 100%;
    height: 430px;
    object-fit: contain;
}

.quote-container {
    position: relative;
    max-width: 600px;
    margin: 75px auto;
}

.quote-container .quote {
    text-align: center;
}

.quote h2 {
    margin: 0;
}

.quote p {
    margin: 18px;
}

.quote h2,
.quote p {
    color: var(--brown);
}

.right-quote {
    position: absolute;
    bottom: 35px;
    right: 0;
}

.related-articles-title {
    background: var(--light-grey);
    padding: 24px 0;
    text-align: center;
}

.related-articles-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    padding: 0;
}

.related-article {
    display: flex;
    flex-direction: column;
    max-width: 270px;
}

@media screen and (max-width: 991px) {
    .article-hero {
        padding: 0;
        margin: 0;
        justify-content: center;
    }

    .article-image {
        width: 100%;
        max-height: 250px;
        position: absolute;
    }

    .article-image img {
        height: 250px;
        width: 100%;
    }

    .article-hero-text-container {
        padding: 24px 32px;
        margin: 40px 16px 40px;
        background: var(--white);
        z-index: 1;
        width: auto;
        flex: auto;
    }


    .article-content-container {
        max-width: 100%;
        margin: unset;
    }

    .article-content-media-container {
        flex-wrap: wrap;
    }

    .video-container {
        max-width: 100%;
        width: 100%;
    }

    .video-container video {
        width: 100%;
        height: 100%;
    }

    .carousel-container {
        width: 100%;
    }

    .related-articles-content {
        padding: 16px 16px 0;
    }

    .article-content-offset {
        padding-left: 0;
    }

    .article-content-offset p.regular {
        max-width: 100%;
    }

    .article-content-offset > div {
        flex-wrap: wrap;
    }

    .right-content {
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .article-image img {
        max-height: 250px;
    }

    .related-articles-content {
        flex-direction: column;
        gap: 16px;
    }

    .related-article {
        max-width: 100%;
    }

    .related-article .article-left-container {
        display: flex;
        flex-direction: row;
        gap: 15px;
        flex-wrap: wrap;
    }

    .related-article .article-left-container > div {
        flex: 1;
    }

    .related-article .article-left-container button.secondary {
        display: none;
    }

    .article-hero-text-container p.regular {
        margin-top: 24px;
    }

    .article-hero > div > div {
        flex-direction: row;
        justify-content: flex-start;
        padding: 0;
    }

    .related-article .article-left-container .line-text-container {
        padding: 0;
    }

    .related-article .article-left-container .line-text-container p.regular {
        display: none;
    }

    .related-article .article-left-image-container img {
        min-height: 150px;
    }

    .article-content-offset {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .article-content-offset button.secondary path {
        stroke: var(--white);
    }

    .article-content-offset button.secondary .arrow-right {
        display: none;
    }

    .article-content-offset .links-container {
        flex-direction: column;
        gap: 8px;
    }

    .article-content-offset .links-container > button {
        width: fit-content;
    }
}

@media screen and (max-width: 480px) {
    .related-article .article-left-container img {
        max-width: unset;
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
}
