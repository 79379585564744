.hero-contact-content-container {
    position: relative;
    margin-top: 168px;
    border: 1px solid var(--black);
    height: 195px;
    width: 700px;
    z-index: 1;
    margin-left: 20px;
}

.hero-contact-content {
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    float: left;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
    background: var(--white);
    width: 620px;
    min-height: 241px;
    position: absolute;
    top: -20px;
    left: -20px;
}

.hero-contact-image {
    position: absolute;
    right: 0;
}

.hero-contact-image img {
    width: 805px;
    object-fit: cover;
}

.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-page > .form-container {
    max-width: 370px;
}

@media screen and (max-width: 1024px) {
    .hero-contact {
        padding-left: 0;
    }

    .hero-contact-image {
        left: 0;
    }

    .hero-contact-image img {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .hero-contact {
        padding-top: 0;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 768px) {
    .hero-contact-content-container {
        width: calc(100% - 30px);
        margin-top: 60px;
    }

    .hero-contact-content {
        width: calc(100% - 85px);
    }


    form {
        max-width: unset;
        padding: 16px;
        margin: unset;
    }
}
