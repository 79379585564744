.legal-notices-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 48px;
    margin: auto auto 80px;
    max-width: 1170px;
    font-family: Roboto, sans-serif;
}

.legal-notices-title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: Roboto, sans-serif;
}

.legal-notices-container h1,
.legal-notices-container h2 {
    font-family: Roboto, sans-serif;
}

ol {
    padding-left: 0;
    list-style: inside decimal;
}

li::marker {
    font-size: 31px;
}

li > h2 {
    display: contents;
    margin: 1em 0 0 0;
    font-family: Roboto, sans-serif;
}

@media screen and (max-width: 991px) {
    .legal-notices-container {
        padding: 0;
        margin: auto 20px 80px;
    }
}

@media screen and (max-width: 768px) {
    .legal-notices-container {
        gap: 40px;
        margin-bottom: 80px;
    }
}
