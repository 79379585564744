/* GOOGLE RECAPTCHA HIDE */
.grecaptcha-badge {
    opacity: 0;
    z-index: 0;
}

footer {
    z-index: 10;
}

.footer-top-container {
    display: flex;
    justify-content: center;
    gap: 110px;
    background: var(--light-grey);
}

.footer-bottom-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 16px 0;
    gap: 40px;
}

.footer-top-container p,
.footer-top-container .caption-regular,
.footer-bottom-container .caption-regular {
    color: var(--dark-grey);
}

.footer-top-categories {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 0 55px;
}

.footer-top-navigation {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 0 32px;
}

.footer-top-navigation a {
    text-decoration: none;
}

/* MDS FOOTER */
.copyright-container {
    position: relative;
    /*display: inline-block;*/
    display: flex;
    align-items: center;
    text-decoration: none;
    z-index: 2;
}

.copyright,
.copyright-colored {
    font-family: 'Roboto';
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    color: var(--dark-grey);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    cursor: pointer;
    width: fit-content;
}

.copyright-code, .copyright-heart {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.copyright svg, .copyright-colored svg {
    transform: translateY(12.5%);
}

.copyright svg path, .copyright-colored svg path {
    fill: var(--dark-grey);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.copyright span, .copyright-colored span {
    fill: var(--dark-grey);
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

.copyright-colored {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: rotateX(90deg) translateZ(9px);
}

.copyright-colored path, .copyright-code path {
    fill: #939393;
}

.copyright-colored span {
    color: #fa6938;
}

.copyright-colored .copyright-heart path {
    fill: #cb1b74;
}

a:focus .copyright, a:hover .copyright {
    transform: rotateX(-90deg) translateZ(9px);
}

a:focus .copyright-colored, a:hover .copyright-colored {
    transform: rotateX(0) translateZ(9px);
}

@media screen and (max-width: 991px) {
    .footer-bottom-container {
        padding-bottom: 90px;
    }
}

@media screen and (max-width: 768px) {
    .copyright span:nth-child(2),
    .copyright-colored span:nth-child(2) {
        display: none;
    }

    .copyright span:nth-child(3):after,
    .copyright-colored span:nth-child(3):after {
        content: 'MDS';
    }
}

@media screen and (max-width: 480px) {
    .footer-top-container {
        justify-content: flex-start;
        padding: 16px;
    }

    .footer-top-categories,
    .footer-top-navigation {
        padding-top: 2px;
        padding-bottom: 16px;
    }
}