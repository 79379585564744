.bio-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 870px;
    padding: 0 16px;
    margin: auto;
}

.bio-content h3 {
    margin-bottom: 31px;
}

.bio-content h3,
.bio-content p {
    color: var(--brown);
}

.bio-content p {
    padding-left: 100px;
}

.bio-content ul {
    padding-left: 120px;
}

.bio-content ul p {
    padding-left: 0;
}

.bio-content li::marker {
    font-size: 12px;
}

.bio-buttons-container {
    display: flex;
    justify-content: center;
    gap: 35px;
    padding: 0 16px;
}

.bio-buttons-container a {
    text-decoration: unset;
}

@media (max-width: 768px) {
    .bio-content p {
        padding-left: 0;
    }

    .bio-content ul {
        padding-left: 20px;
    }

    .bio-buttons-container {
        /*display: none;*/
    }
}
